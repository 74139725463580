import React from 'react'
import { Link } from 'react-router-dom'

// components
import Avatar from '../../../../components/Avatar'

// styles
import './ProjectList.css'

export default function ProjectList({ projects }) {
 return (
  <div className='project-list'>
   {projects.length == 0 && <p>No projects</p>}
   {projects.map(project => (
    <Link key={project.id} to={`/projects/${project.id}`}>
     <h4>{project.name}</h4>
     <p>Due by {project.dueDate.toDate().toDateString()}</p>
     <div className="assigned-to">
      <ul>
       {project.assignedUsersList.map(user => (
        <li key={user.photoURL}>
         <Avatar src={user.photoURL} />
        </li>
       ))}
      </ul>
     </div>
    </Link>
   ))}
  </div>
 )
}
