import React from 'react'


// components
import Header from '../../components/header/Header'
import Skills from '../../components/skills/Skills'
import Services from '../../components/services/Services'

// assets
import AboutImage from '../../assets/images/about.jpeg'


// styles
import './Core.css'


export default function About() {
  return (<>

    <div className="page-home">
      <div className="content-divider"></div>


      {/* ABOUT SECTION */}
      <section className="section-center about-center clearfix" id="about">
        <article className="about">
          <div className="about-picture-container">
            <img src={AboutImage} alt="about" className="about-picture" />
          </div>
        </article>

        {/* about info */}
        <article className="about">
          <div className="section-title">
            <h2>about <span>us</span></h2>
          </div>
          <p className="about-text">The world of technology can be fast-paced and scary. That's why our goal is to provide a high-quality product that aligns with your company's needs. No matter the budget, we pride ourselves on providing professional customer service. We guarantee you will be satisfied with our work. </p>
          
          <p className="about-text">At our company, we turn the digital dreams of our clients into a reality. We work closely with our users throughout development to ensure that we are still aligned with the end-goal. We are committed to producing exceptional software for each of our clients.</p>
          <p className="about-text">We are one of the leading, Microsoft Dynamics implementation partners in the UK. Our team of Microsoft Dynamics Consultants have in-depth industry experience and detailed technical knowledge of Microsoft Dynamics 365 coupled with Microsoft business intelligence (BI) solutions. Our specialist Dynamics 365 consultancy team has experience across a broad range of verticals and their extended supply chains.</p>
          <a href="/about" class="btn">learn more</a>
        </article>
        <article className='about'>
          <div className="section-title">
            <h2>Our <span>Aim</span></h2>
          </div>
          <p>
            To solve problems, improve productivity and customer relations through the use of bespoke software. We believe that bespoke software that has been designed for a specific purpose can offer greater benefit over using commercial off the shelf software.
          </p>
        </article>
        <article className='about'>
          <div className="section-title">
            <h2>Our <span>Approach</span></h2>
          </div>
          <p>
            We follow current software development best practise. Throughout the software development process we encourage constant feedback to ensure the system meets all the end user requirements​.
          </p>
        </article>
        <article className='about'>
          <div className="section-title">
            <h2>Outcome</h2>
          </div>
          <p>
            Ensuring the completed solution is tailored to your exact needs which succeeds in paying for itself through increased business throughput or increased customer satisfaction.​
          </p>
        </article>
        
      </section>
      <div className="content-divider"></div>


      {/* SERVICES SECTION */}
      <Services />

    </div>
  </>
  )
}
