import React from 'react'

//styles
import './Header.css'

//components
import Navbar from '../navbar/Navbar'

export default function Header() {
 return (
  <header id="home">
   {/* hero */}
   <div className="hero">
    <div className="hero-banner">
     <h1 className='hero-title'>powering the growth</h1>
     <p>
      Bringing your Dynamics 365 and CRM Projects to Life
     </p>
     <a href="#featured" class="btn hero-btn scroll-link">Explore</a>
    </div>
   </div>
  </header>
 )
}
