import React from 'react'

// styles
import './Services.css'

const Service = ({id, title, description, image, icon, url}) => {
 return (
  <article className="service-card">
   <div className="service-img-container">
    {/* service image */}
    <img src={image} alt="" className="service-img" />
    {/* service Icon */}

    <span className="service-icon">
     <i className={icon}></i>
    </span>
   </div>

   <div className="service-info">
    <h4>{title}</h4>
    <p>{description}</p>
    <a href={url} className='btn service-btn'>read more</a>
   </div>
  </article>
 )
}

export default Service
