import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

// styles
import './App.css'

// Pages and components

// components
// import Navbar from './components/Navbar';
import Header from './components/header/Header'
import Ruler from './components/ruler/Ruler';
import Navbar from './components/navbar/Navbar'
import Sidebar from './components/navigation/Sidebar';
import OnlineUsers from './components/OnlineUsers';
import Footerbar from './components/Footerbar';


// core pages
import Home from './pages/core/Home';
import About from './pages/core/About';
import OurServices from './pages/core/OurServices';
import Contact from './pages/core/Contact';
import Login from './pages/auth/Login'
import Signup from './pages/auth/Signup'


// Authenticated users
import Dashboard from './pages/admin/Dashboard';
import Project from './pages/admin/projects/components/Project';
import CreateProject from './pages/admin/projects/components/CreateProject';
import Support from './pages/admin/Support';
import Test from './pages/admin/Test';


// Auth pages
import Projects from './pages/admin/projects/Projects';
import ServicesAdmin from './pages/admin/services/ServicesAdmin';


function App() {
  const { user, authIsReady } = useAuthContext()
  return (
    <div className="App">

      {authIsReady && (
        <BrowserRouter>
          {/* <Sidebar /> */}
          <div className="container">
            <Navbar />
            <Switch>
              {/* core Pages */}
              <Route exact path="/">
                <>
                  <Home />
                </>
              </Route>
              <Route path="/about">
                <>
                  <About />
                </>
              </Route>
              <Route exact path="/ourservices">
                <>
                  <OurServices />
                </>
              </Route>
              <Route exact path="/contact">
                <>
                  <Contact />
                </>
              </Route>
              <Route exact path="/login">
                {user && <Redirect to="/" />}
                {!user && <Login />}
              </Route>
              <Route exact path="/signup">
                {user && <Redirect to="/" />}
                {!user && <Signup />}
              </Route>
              {/* projects */}
              <Route exact path="/dashboard">
                {!user && <Redirect to="/login" />}
                {user && <Dashboard />}
              </Route>
              <Route exact path="/support">
                {!user && <Redirect to="/login" />}
                {user && <Support />}
              </Route>
              <Route exact path="/test">
                {!user && <Redirect to="/login" />}
                {user && <Test />}
              </Route>
              <Route exact path="/admin/projects">
                {!user && <Redirect to="/login" />}
                {user && <Projects />}
              </Route>
              <Route path="/projects/create">
                {!user && <Redirect to="/login" />}
                {user && <CreateProject />}
              </Route>
              <Route path="/projects/:id">
                {!user && <Redirect to="/login" />}
                {user && <Project />}
              </Route>
              <Route exact path="/admin/services">
                {!user && <Redirect to="/login" />}
                {user && <ServicesAdmin />}
              </Route>
            </Switch>
            <Footerbar />
          </div>
          {/* {user && <OnlineUsers />} */}
        </BrowserRouter>
      )}
    </div>
  );
}

export default App

// Pages
// - home
// - dashboard (homepage)
// - login
// - signup
// - create
// - project (project details)

