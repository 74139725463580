import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'

// styles
import './Ruler.css';

function getWindowDimensions() {
 const { innerWidth: width, innerHeight: height } = window;
 return {
  width,
  height
 };
}

const Ruler = () => {
 const { height, width } = useWindowDimensions();
 /* you can also use default values or alias to use only one prop: */
 // const { height: windowHeight = 480 } useWindowDimensions();

 return (
  <div className='ruler'>
   <span>width: {width} ~ height: {height}</span>
  </div>
 );
}

export default Ruler
