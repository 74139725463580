import React, { useState } from 'react'

// hooks
import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from '../../hooks/useAuthContext'

// components
import ProjectList from './projects/components/ProjectList'
import ProjectFilter from './projects/components/ProjectFilter'
import Sidebar from '../../components/navigation/Sidebar';
import OnlineUsers from '../../components/OnlineUsers';

// styles
import './Admin.css'

export default function Dashboard() {
  const { documents, error } = useCollection('projects')
  const [currentFilter, setCurrentFilter] = useState('all')
  const { user } = useAuthContext()

  const changeFilter = (newFilter) => {
    setCurrentFilter(newFilter)
  }

  const projects = documents ? documents.filter((document) => {
    switch (currentFilter) {
      case 'all':
        return true

      case 'mine':
        let assignedToMe = false
        document.assignedUsersList.forEach((u) => {
          if (user.uid === u.id) {
            assignedToMe = true
          }
        })
        return assignedToMe

      case 'development':
      case 'design':
      case 'marketing':
      case 'sales':
        return document.category === currentFilter

      default:
        return true
    }
  }) : null

  return (
    <div className='admin-page-container'>
      <Sidebar />
      <div className="dashboard">
        <div className="dashboard-header">
          <h2>Dashboard</h2>
          {documents && (
            <ProjectFilter currentFilter={currentFilter} changeFilter={changeFilter} />
          )}
        </div>
        <div className="dashboard-content">
          {error && <p className='error'>{error}</p>}
          {projects && <ProjectList projects={projects} />}
        </div>
      </div>
    </div>
  )
}
