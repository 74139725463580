import React from 'react'
import { Link } from 'react-router-dom'

// components
import Avatar from '../../../../components/Avatar'

// styles
import './ServiceList.css'

export default function ServiceList({ services }) {
 return (
  <div className='service-list'>
   {services.length == 0 && <p>No services</p>}
   {services.map(service => (
    <Link key={service.id} to={`/services/${service.id}`}>
     <h4>{service.name}</h4>
     <p>Due by {service.dueDate.toDate().toDateString()}</p>
     <div className="assigned-to">
      <ul>
       {service.assignedUsersList.map(user => (
        <li key={user.photoURL}>
         <Avatar src={user.photoURL} />
        </li>
       ))}
      </ul>
     </div>
    </Link>
   ))}
  </div>
 )
}
