import React, { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'
import Select from 'react-select'

// styles
import './Auth.css'
import Footerbar from '../../components/Footerbar'

const titles = [
  { value: 'mr', label: 'Mr' },
  { value: 'mrs', label: 'Mrs' },
  { value: 'miss', label: 'Miss' },
  { value: 'ms', label: 'Ms' },
  { value: 'master', label: 'Master' },

  // professional
  { value: 'dr', label: 'Dr' },
  { value: 'professor', label: 'Professor' },
  { value: 'sir', label: 'Sir' },
  { value: 'lord', label: 'Lord' },
  { value: 'lady', label: 'Lady' },

  // Islamic Titles
  { value: 'haafiz', label: 'Hāfiz' },
  { value: 'haafizah ', label: 'Hāfizah' },
  { value: 'haji', label: 'Hājī ' },
  { value: 'mawlaanaa ', label: 'Mawlānā' },
  { value: 'mufti: ', label: 'Muftī' },
  { value: 'qaarii:', label: 'Qārī' },
  { value: 'shaykh', label: 'Shaykh' },
]

export default function Signup() {

  const { signup, isPending, error } = useSignup()

  // form fields
  const [title, setTitle] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailerror, setThumbNailError] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    const registration = {
      title,
      firstName,
      lastName,
      displayName,
      email,
      password,
      thumbnail
    }

    signup(registration)
  }

  const handleFileChange = (e) => {
    setThumbnail(null)
    let selected = e.target.files[0]
    console.log(selected)

    if (!selected) {
      setThumbNailError('Please select a file')
      return
    }

    if (!selected.type.includes('image')) {
      setThumbNailError('Select file must be an image')
      return
    }

    if (selected.size > 100000) {
      setThumbNailError('Image file size must be less then 100kb')
      return
    }

    setThumbNailError(null)
    setThumbnail(selected)
    console.log('Thumbnail updated')

  }

  return (
    <div className="page-container">

      <form className='page-container auth-form' onSubmit={handleSubmit}>
        <h2>Signup</h2>
        <label>
          <div className="label-container">
            <p>title:</p>
            <span className='is-required'>*</span>
          </div>
          <Select
            options={titles}
            onChange={(option) => setTitle(option)}
          />
        </label>
        <label>
          <div className="label-container">
            <p>First name:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </label>
        <label>
          <div className="label-container">
            <p>last name:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </label>
        <label>
          <div className="label-container">
            <p>display name:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="text"
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
        </label>
        <label>
          <div className="label-container">
            <p>photo:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="file"
            onChange={handleFileChange}
          />
          {thumbnailerror && <div className='error'>{thumbnailerror}</div>}
        </label>
        <hr />
        <label>
          <div className="label-container">
            <p>email address:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <div className="label-container">
            <p>password:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </label>
        {console.log(`Signup: IsPending:${isPending}`)}
        {!isPending && <button className="btn auth-btn">Signup</button>}
        {isPending && <button className="btn auth-btn" disabled>Please wait</button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </div>
  )
}
