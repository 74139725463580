import React from 'react'


// components
import Header from '../../components/header/Header'
import Skills from '../../components/skills/Skills'
import Services from '../../components/services/Services'

// assets
import AboutImage from '../../assets/images/about.jpeg'


// styles
import './Core.css'


export default function Home() {
  return (<>

    <div className="page-home">
      <Header />
      <div className="content-divider"></div>
      <Skills />
 
      {/* ABOUT SECTION */}
      <section className="section-center about-center clearfix" id="about">
        <article className="about">
          <div className="about-picture-container">
            <img src={AboutImage} alt="about" className="about-picture" />
          </div>
        </article>

        {/* about info */}
        <article className="about">
          <div className="section-title">
            <h2>about <span>us</span></h2>
          </div>
          <p className="about-text">At our company, we turn the digital dreams of our clients into a reality. We work closely with our users throughout development to ensure that we are still aligned with the end-goal. We are committed to producing exceptional software for each of our clients.</p>

          <p className="about-text">The world of technology can be fast-paced and scary. That's why our goal is to provide a high-quality product that aligns with your company's needs. No matter the budget, we pride ourselves on providing professional customer service. We guarantee you will be satisfied with our work. </p>
          <a href="/about" class="btn">learn more</a>
        </article>
      </section>

      {/* SERVICES SECTION */}
      <Services />

    </div>
  </>
  )
}
