import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

// hooks
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext';

// assets
import logo from '../../images/logo.svg';
import { FaUserCircle, FaBars, FaTwitter } from 'react-icons/fa';
import { links, social } from './links';
import Avatar from '../Avatar';


// styles
import './Navbar.css'

const Navbar = () => {
 const { logout, isPending } = useLogout()
 const { user, authIsReady } = useAuthContext()

 const [showLinks, setShowLinks] = useState(false);
 const linksContainerRef = useRef(null);
 const linksRef = useRef(null);

 // Callback function to run every time the showLinks Value changes
 useEffect(() => {
  //Check the height of the links
  const linksHeight = linksRef.current.getBoundingClientRect().height;

  if (showLinks) {
   linksContainerRef.current.style.height = `${linksHeight}px`;
  } else {
   linksContainerRef.current.style.height = `0px`;
  }
 }, [showLinks]);

 return (
  <nav>
   <div className='nav-center'>
    <div className='nav-header'>
     <img src={logo} alt='logo' className='logo' />
     <button
      className='nav-toggle'
      onClick={() => setShowLinks(!showLinks)}>
      <FaBars />
     </button>
    </div>

    <div className='links-container' ref={linksContainerRef}>
     <ul className='links' ref={linksRef}>
      {links.map((link) => {
       const { id, text, url } = link;
       return (
        <li key={id}>
         {/* <a id={id} href={url}> {text} </a> */}
         <Link id={id} to={url} className='nav-link scroll-link'>{text}</Link>
        </li>
       );
      })}

      {/* login links */}
      {/* {!user && (
       <li><Link to='/signup' className='nav-link scroll-link'>Signup</Link>
       </li>
      )} */}
      {user && (
       <li><Link to='/dashboard' className='nav-link scroll-link'>Dashboard</Link>
       </li>
      )}

      {!user && (
       <li>
        <Link to='/login' className='nav-link scroll-link'>Login</Link>
       </li>
      )}
      {user && (
       <div className="logged-in-user">
        {/* <p className="user-name">{user.displayName}</p> */}
        <li>
         {!isPending && <button className="btn" onClick={logout}>Logout</button>}
         {isPending && <button className="btn" onClick={logout}>Please wait - Logging out ...</button>}
        </li>
        {/* <Avatar src={user.photoURL} className='user-avatar' /> */}
       </div>
      )}
     </ul>
    </div>

    <ul className='social-icons'>
     {social.map((link) => {
      const { id, icon, url } = link;
      return (
       <li key={id}>
        <a id={id} href={url} target="_blank">
         {icon}
        </a>
       </li>
      );
     })}
    </ul>
   </div>
  </nav>
 );
};

export default Navbar;
