import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


// Add the firebase config here
const firebaseConfig = {
 apiKey: "AIzaSyCcyuW1jpNQIWozhwnJE6VpbzJ63-OWQCs",
 authDomain: "code8software-38957.firebaseapp.com",
 projectId: "code8software-38957",
 storageBucket: "code8software-38957.appspot.com",
 messagingSenderId: "415148289838",
 appId: "1:415148289838:web:24c320f0f5d429212354bf"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }