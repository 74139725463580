import React, { useState } from 'react'

// hooks
import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from '../../hooks/useAuthContext'

// components
import Sidebar from '../../components/navigation/Sidebar';

// styles
import './Admin.css'

export default function Support() {
 const { documents, error } = useCollection('tickets')
 const { user } = useAuthContext()

 return (
  <div className='admin-page-container'>
   <Sidebar />
   <div className="dashboard">
    <div className="dashboard-header">
     <h2>Support</h2>
    </div>
    <div className="dashboard-content">
     <h3>Raised tickets</h3>
    </div>
   </div>
  </div>
 )
}
