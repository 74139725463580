import React from 'react'

// components
import Skill from './Skill'

// styles
import './Skill.css'

const Skills = () => {
 return (
  <section className="skills clearfix" id="skills">
   <Skill icon="fas fa-crosshairs" title="Our Aim" text="Code8 delivers software solutions tailored to the exact requirements of our customers." />
   <Skill icon="fas fa-code" title="Our Development" text="We use field proven and reliable rapid application development techniques and ensure all work is future proof." />
   <Skill icon="fas fa-user-plus" title="Our Team" text="Our team has been developing solutions for over 15 years. With years of experience we are confident we can deliver your required solution within your timescale and budget." />
   <Skill icon="fas fa-thumbs-up" title="Satisfaction" text="We pride ourselves in being committed to every project we take on and to providing a high quality service on time every time." />
  </section>
 )
}

export default Skills
