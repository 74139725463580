import React, { useState, useEffect } from 'react'
import { projectFirestore } from '../firebase/config'

export const useDocument = (collection, id) => {
 const [document, setDocument] = useState(null)
 const [error, setError] = useState(null)

 // realtime date for the document

 useEffect(() => {
  const ref = projectFirestore.collection(collection).doc(id)

  // use snapshot method to get realtime data
  const unsubscribe = ref.onSnapshot((snapshot) => {

   if (snapshot.data()) {
    setDocument({ ...snapshot.data(), id: snapshot.id })
    setError(null)
   }
   else {
    setError('Document not found')
   }

  }, (err) => {
   console.log(err.message)
   setError('failed to get the document')
  })

  // cleanup function

  return () => unsubscribe()

 }, [collection, id])

 // return the hook values
 return { document, error }
}
