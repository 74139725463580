import React from 'react'

const Skill = ({ icon, title, text }) => {
 return (
  <article className="skill">
   <span className="skill-icon">
    <i className={icon}></i>
    <h4 className="skill-title">{title}</h4>
    <p className='skill-text'>{text}</p>
   </span>
  </article>
 )
}

export default Skill
