import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'

// styles and images
import './Sidebar.css'
import { FaProjectDiagram, FaProductHunt } from "react-icons/fa";
import { BsGear, BsLifePreserver } from "react-icons/bs";
import { AiOutlinePieChart, AiOutlineCloudServer } from "react-icons/ai";

import Avatar from '../Avatar'

export default function Sidebar() {
 const { user } = useAuthContext()

 return (
  <div className='sidebar'>
   <div className="sidebar-content">
    <div className="user">
     {/* avatar or username later */}
     {user && (
      <>
       <Avatar src={user.photoURL} />
       <p className='user-name'>Hey, {user.displayName}</p>
      </>
     )
     }

    </div>
    <nav className="links">
     <ul>
      <li>
       <NavLink exact to="/dashboard">
        <AiOutlinePieChart className='sidebar-icon' />
        <span>Dashboard</span>
       </NavLink>
      </li>

      <li>
       <NavLink exact to="/admin/projects">
        <FaProjectDiagram className='sidebar-icon' />
        <span>Projects</span>
       </NavLink>
      </li>
      <li>
       <NavLink exact to="/admin/services">
        <AiOutlineCloudServer className='sidebar-icon' />
        <span>Services</span>
       </NavLink>
      </li>
      {/* <li>
       <NavLink to="/projects/create">
        <img src={AddIcon} alt="Add Icon" />
        <span>New Project</span>
       </NavLink>
      </li> */}
      <hr />
      <li>
       <NavLink exact to="/support">
        <BsLifePreserver className='sidebar-icon' />
        <span>Support</span>
       </NavLink>
      </li>
      <li>
       <NavLink exact to="/test">
        <BsGear className='sidebar-icon' />
        <span>Test</span>
       </NavLink>
      </li>
     </ul>
    </nav>
   </div>
  </div>
 )
}
