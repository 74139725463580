import React from 'react'
import { useFirestore } from '../../../../hooks/useFirestore'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useHistory } from 'react-router-dom'

// components
// import Avatar from '../../../../components/Avatar'
import Avatar from '../../../../components/Avatar'

export default function ProjectSummary({ project }) {
 const history = useHistory()
 const { deleteDocument, updateDocument } = useFirestore('projects')
 const { user } = useAuthContext()

 const handleComplete = (e) => {
  updateDocument(project.id, { status: 'completed' })
  history.push('/')
 }

 const handleDelete = (e) => {
  deleteDocument(project.id)
  history.push('/')
 }

 return (
  <div className='project-summary'>
   <h2 className="page-title">{project.name}</h2>
   <p className='project-owner'>Project Owner: {project.createdBy.displayName}</p>

   {project.status == 'pending' && <p className='status-pending'>{project.status}</p>}
   {project.status == 'inprogress' && <p className='status-inprogress'>{project.status}</p>}
   {project.status == 'completed' && <p className='status-completed'>{project.status}</p>}

   <p className="due-date">Due by {project.dueDate.toDate().toDateString()}</p>
   <p className="details">{project.details}</p>
   <h4>Project is assigned to:</h4>
   <div className="assigned-users">
    {project.assignedUsersList.map(user => (
     <div key={user.id}>
      <Avatar src={user.photoURL} />
     </div>
    ))}
   </div>
   {user.uid === project.createdBy.id && (<>
    <button className="btn-success" onClick={handleComplete}>Mark as Completed</button>
    <button className="btn-danger" onClick={handleDelete}>Delete</button>
   </>
   )}
  </div>
 )
}



