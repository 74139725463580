import React from 'react'
// hooks
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

// styles
import './ContactForm1.css'

const ContactForm1 = () => {
 const {
  register,
  handleSubmit,
  reset,
  formState: { errors }
 } = useForm();


 const onSubmit = async (data) => {
  const { firstname, lastname, companyname, email, phone, subject, message } = data;

  try {
   const templateParams = {
    firstname,
    lastname,
    companyname,
    email,
    phone,
    subject,
    message
   };
   await emailjs.send(
    process.env.REACT_APP_SERVICE_ID,
    process.env.REACT_APP_TEMPLATE_ID,
    templateParams,
    process.env.REACT_APP_USER_ID
   );
   reset();
  } catch (e) {
   console.log(e);
  }
 };

 return (
  <div className='ContactForm'>
   <div className='container'>
    <div className='row'>
     <div className='col-12 text-center'>
      <div className='contactForm'>
       <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Row 1 of form */}
        <div className='row formRow'>
         <div>
          <input type='text' name='firstname'
           {...register('firstname', {
            required: { value: true, message: 'Please enter your first name' },
            maxLength: {
             value: 30,
             message: 'Please use 30 characters or less'
            }
           })}
           className='form-control formInput'
           placeholder='Your firstname'
          ></input>
          {errors.firstname && <span className='errorMessage'>{errors.firstname.message}</span>}
         </div>
         <div>
          <input type='text' name='lastname'
           {...register('lastname', {
            required: { value: true, message: 'Please enter your last name' },
            maxLength: {
             value: 30,
             message: 'Please use 30 characters or less'
            }
           })}
           className='form-control formInput'
           placeholder='Your lastname'
          ></input>
          {errors.lastname && <span className='errorMessage'>{errors.lastname.message}</span>}
         </div>
         <div>
          <input type='text' name='companyname'
           {...register('companyname', {
            required: { value: true, message: 'Please enter your company name' },
            maxLength: {
             value: 30,
             message: 'Please use 30 characters or less'
            }
           })}
           className='form-control formInput'
           placeholder='Your company name'
          ></input>
          {errors.companyname && <span className='errorMessage'>{errors.companyname.message}</span>}
         </div>
         <div className='col-6'>
          <input
           type='email'
           name='email'
           {...register('email', {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
           })}
           className='form-control formInput'
           placeholder='The email address you would use for us to get in touch'
          ></input>
          {errors.email && (
           <span className='errorMessage'>Please enter a valid email address</span>
          )}
         </div>
         <div className='col-6'>
          <input
           type="phone"
           name='phone'
           {...register('phone', {
            required: true,
            pattern: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
           })}
           className='form-control formInput'
           placeholder='The mobile or landline that we can call you on'
          ></input>
          {errors.phone && (
           <span className='errorMessage'>Please enter a valid phone number</span>
          )}
         </div>
        </div>
        {/* Row 2 of form */}
        <div className='row formRow'>
         <div className='col'>
          <input
           type='text'
           name='subject'
           {...register('subject', {
            required: { value: true, message: 'Please enter a subject' },
            maxLength: {
             value: 75,
             message: 'Subject cannot exceed 75 characters'
            }
           })}
           className='form-control formInput'
           placeholder='Subject'
          ></input>
          {errors.subject && (
           <span className='errorMessage'>{errors.subject.message}</span>
          )}
         </div>
        </div>
        {/* Row 3 of form */}
        <div className='row formRow'>
         <div className='col'>
          <textarea
           rows={3}
           name='message'
           {...register('message', {
            required: true
           })}
           className='form-control formInput'
           placeholder='Message'
          ></textarea>
          {errors.message && <span className='errorMessage'>Please enter a message</span>}
         </div>
        </div>
        <button className='submit-btn' type='submit' className="btn">
         Submit
        </button>
       </form>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default ContactForm1;