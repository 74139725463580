import { useState, useEffect } from 'react'
import { projectAuth, projectStorage, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (registration) => {
    if (!registration) {
      return;
    }

    // title, firstname, surname, displayName, email, password, thumbnail
    setError(null)
    setIsPending(true)

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(registration.email, registration.password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // upload user thumbnail
      const uploadPath = `thumbnails/${res.user.uid}/${registration.thumbnail.name}`
      const img = await projectStorage.ref(uploadPath).put(registration.thumbnail)
      const imgUrl = await img.ref.getDownloadURL()

      // console.log(`PhotoURL : ${imgUrl}`)

      // Update profile with displayname and url
      await res.user.updateProfile({ displayName: registration.displayName, photoURL: imgUrl })

      // Create a user document in the database
      await projectFirestore.collection('users').doc(res.user.uid).set({
        title: registration.title,
        firstName: registration.firstName,
        lastName: registration.lastName,
        displayName: registration.displayName,
        gender: '',
        email: registration.email,
        mobile: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        postcode: '',
        photoURL: imgUrl,

        // additional info
        bio: '',
        interests: '',
        languages: [],
        links: [],
        roles: [],
        isadmin:true,
        isclient:true,
        status: 'pending',
        online: true,
      })

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      try {
        setIsPending(false)
        setError(null)
      } catch (err) {
        console.log(err.message)
      }

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }

      console.log(`useSignup: IsCancelled : ${isCancelled}  IsPending:${isPending}`)
    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}