import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// Hooks
import { useLogin } from '../../hooks/useLogin'

// styles
import './Auth.css'
import Footerbar from '../../components/Footerbar';

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, isPending, error } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()

    //Login user
    login(email, password)
  }

  return (
    <div className="page-container">

      <form className='auth-form' onSubmit={handleSubmit}>
        <h2>Login</h2>
        <label>
          <div className="label-container">
            <p>email address:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <div className="label-container">
            <p>password:</p>
            <span className='is-required'>*</span>
          </div>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div className="register-container">
            <p>Not a member?</p>
              <Link to='/signup' className='register-link'>Register</Link>
            
          </div>

        </label>
        {/* {console.log(`Login: IsPending:${isPending}`)} */}
        {/* <button className="btn">Login</button> */}

        {!isPending && <button className="btn auth-btn">Login</button>}
        {isPending && <button className="btn auth-btn" disabled>Please wait</button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </div>
  )
}
