import React, { useState } from 'react'

// hooks

import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from '../../../hooks/useAuthContext'

// components
import Sidebar from '../../../components/navigation/Sidebar';
import ServiceList from './components/ServiceList';
import ServiceFilter from './components/ServiceFilter';

// styles
import './ServicesAdmin.css'

export default function ServicesAdmin() {

  const { documents, error } = useCollection('services')
  const [currentFilter, setCurrentFilter] = useState('all')
  const { user } = useAuthContext()

  const changeFilter = (newFilter) => {
    setCurrentFilter(newFilter)
  }

  const services = documents ? documents.filter((document) => {
    switch (currentFilter) {
      case 'all':
        return true

      case 'mine':
        let assignedToMe = false
        document.assignedUsersList.forEach((u) => {
          if (user.uid === u.id) {
            assignedToMe = true
          }
        })
        return assignedToMe

      case 'development':
      case 'design':
      case 'marketing':
      case 'sales':
        return document.category === currentFilter

      default:
        return true
    }
  }) : null

  return (
    <div className='admin-page-container'>
      <Sidebar />
      <div className="service">
        <div className="service-header">
          <h2>services</h2>
          {documents && (
            <ServiceFilter currentFilter={currentFilter} changeFilter={changeFilter} />
          )}
        </div>
        <div className="service-content">
          {error && <p className='error'>{error}</p>}
          {services && <ServiceList services={services} />}
        </div>
      </div>
    </div>
  )
}
