import React from 'react'
import { Link } from 'react-router-dom'


// components
import Header from '../../components/header/Header'
import ContactForm1 from '../../forms/ContactForm1'
import { FaHome, FaEnvelope, FaPhone } from 'react-icons/fa';

// hooks
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext';

// assets

// styles
import './Core.css'


export default function Contact() {
  const { logout, isPending } = useLogout()
  const { user, authIsReady } = useAuthContext()
  return (<>

    <div className="page-home">
      <div className="content-divider"></div>

      {/* ABOUT SECTION */}
      <section className="section-center about-center clearfix" id="about">

        {/* about info */}
        <article className="about">
          <div className="section-title">
            <h2>Get in <span>touch</span></h2>
            <h4>Need some help or advice?</h4>
            {!user && (
              <h4>
                Already a Code8 Customer?   <Link to='/login' className='nav-link scroll-link'>Login</Link>
              </h4>
            )}

          </div>

          <div className="contact-info">
            <p className="contact-text">When you need information, help or support, getting straight through to the right person with the right answers is key and makes all the difference.</p>
            <div className="address">
              <FaHome className='icon' />
              <h4>Code8 Software Development</h4>
              <p>Quadrant Court</p>
              <p>49 Calthorpe Road</p>
              <p>Birmingham</p>
              <p>West Midlands</p>
              <p>B15 1TH</p>
            </div>
            <div className="contact-info contact-details">
              <div className="icon-container">
                <FaPhone className='icon' />
                <span >0121 828 3984</span>
              </div>
              <div className="icon-container">
                <FaEnvelope className='icon' />
                <span >info@code8.co.uk</span>
              </div>
              <div className="icon-container">
                <FaEnvelope className='icon' />
                <span >support@code8.co.uk</span>
              </div>
            </div>


          </div>





        </article>
        <article className="about">
          <ContactForm1 />
        </article>
      </section>
      <div className="content-divider"></div>


    </div>
  </>
  )
}
